import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import useDateFns from '@hooks/useDateFns'

import Container from '@objects/container'
import Headline from '@objects/headline'
import MediaGallery from '@components/mediaGallery'

function MediaPage({ data, pageContext }) {
  const mediaList = get(data, 'contentfulMediaList')
  const { formatDate } = useDateFns()
  const files = mediaList?.files?.map((media) => ({
    ...media,
    createdAt: formatDate(media.createdAt),
  }))

  return (
    <>
      <Container
        nopadding
        className="pt-4 pb-0"
        role="region"
        aria-label="Media Seite"
      >
        <Headline ariaLabel={mediaList.title}>{mediaList.title}</Headline>{' '}
        <p className="font-normal text-black-650">
          (<span className="mr-0.5">{files?.length}</span>
          <span>
            <FormattedMessage id="downloads.elements" />
          </span>
          )
        </p>
      </Container>
      <Container nopadding className="py-11" role="region">
        <MediaGallery files={files} showmore loadpershowmore={4} />
      </Container>
    </>
  )
}

MediaPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default MediaPage

export const pageQuery = graphql`
  query MediaListBySlug($slug: String!, $locale: String!) {
    contentfulMediaList(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      files {
        ... on ContentfulImage {
          __typename
          id
          createdAt
          image {
            file {
              url
            }
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
        }
        ... on ContentfulVideo {
          __typename
          id
          createdAt
          thumbnail {
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
            title
          }
          video {
            file {
              url
            }
          }
        }
      }
    }
  }
`
